<template>
  <div
    ref="rootEl"
    class="focus-ribbon p-xxxl"
    :class="`bg-${content.background_color || 'cobalt'}`"
  >
    <div class="focus-ribbon__bg-image">
      <img
        v-if="content.mobile_picture && isMobile"
        ref="imgEl"
        v-src="content.mobile_picture"
        :sets="['480', '640', '960', '1280']"
        :alt="content.mobile_picture.alt"
        sizes="100vw"
      />
      <img
        v-else-if="content.picture && !isMobile"
        ref="imgEl"
        v-src="content.picture"
        :sets="['1280', '1440', '1600', '1920', '2240', '2560', '2880']"
        :alt="content.picture.alt"
        sizes="100vw"
      />
    </div>
    <div ref="contentEl" class="focus-ribbon__ribbon">
      <h1 class="focus-ribbon__ribbon__title h2" v-html="content.title"></h1>

      <Action
        v-if="content.link"
        :content="{ ...content.link, modifiers: ['btn'] }"
        class="focus-ribbon__links__item"
      />

      <div v-if="content.links?.length" class="focus__links">
        <Action
          v-for="(link, index) in content.links.filter(
            link => link !== undefined
          )"
          :key="link?.url || index"
          :content="{
            ...link,
            modifiers: index === 0 ? ['btn', 'cta'] : ['btn', 'black'],
          }"
          class="focus-ribbon__links__item"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { onMounted, onUnmounted, ref } from 'vue'

import { useUiStore } from '@/stores/ui'
import { useResponsive } from '@/utils/aware'
import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'
import type { Link, Picture } from '@/types'

interface FocusRibbon {
  picture?: Picture
  mobile_picture?: Picture
  title: string
  text: string
  link?: Link
  links?: Link[]
  background_color: string
}

gsap.registerPlugin(ScrollTrigger)

defineProps({
  content: {
    type: Object as PropType<FocusRibbon>,
    required: true,
  },
})

// Refs
const rootEl = ref<HTMLElement | null>(null)
const imgEl = ref<HTMLElement | null>(null)
const contentEl = ref<HTMLElement | null>(null)

const ui = useUiStore()
const isMobile = useResponsive('smaller', 'm')

const appear = () => {
  if (!rootEl.value) {
    return
  }

  const animatedEls: Element[] = [rootEl.value]
  const tl = gsap.timeline({
    onComplete: () => {
      gsap.set(animatedEls, { clearProps: 'all' })
      ui.waitForHero = false

      // Add scrolling animation on picture
      if (imgEl.value) {
        gsap.to(imgEl.value, {
          y: 100,
          ease: 'power4.easeOut',
          scrollTrigger: {
            trigger: rootEl.value,
            start: 'top top',
            end: 'bottom top',
            scrub: 0,
            id: 'focus-ribbon',
          },
        })
      }
    },
  })

  tl.set(rootEl.value, { clearProps: 'opacity' }).fromTo(
    rootEl.value,
    {
      clipPath: 'inset(0% 0% 20% 0%)',
    },
    {
      clipPath: 'inset(0% 0% 0% 0%)',
      duration: 0.5,
      ease: '8020',
    }
  )

  if (contentEl.value) {
    const contentChildrenEl = contentEl.value.querySelectorAll(':scope > *')

    animatedEls.push(contentEl.value)
    animatedEls.push(...contentChildrenEl)

    tl.fromTo(
      contentEl.value,
      {
        xPercent: -20,
      },
      {
        xPercent: 0,
        duration: 0.5,
        ease: '8020',
      },
      0
    ).fromTo(
      contentChildrenEl,
      {
        x: -30,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.05,
        ease: '8020',
      },
      '<+=0.1'
    )
  }

  if (imgEl.value) {
    animatedEls.push(imgEl.value)

    tl.fromTo(
      imgEl.value,
      {
        opacity: 0,
        scale: 1.2,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.8,
        ease: '8020',
      },
      0
    )
  }
}

onMounted(() => {
  if (!rootEl.value) {
    return
  }

  // Init hero appear
  ui.waitForHero = true
  gsap.set(rootEl.value, { opacity: 0 })

  // Animate on appear
  onAppear(
    rootEl.value,
    () => {
      appear()
    },
    {
      once: true,
      rootMargin: '0px 0px -20% 0px',
      waitForHero: false,
    }
  )
})

onUnmounted(() => {
  ScrollTrigger?.getById('focus-ribbon')?.kill()
})
</script>

<style lang="scss" scoped>
$ribbon-w: 62.5;

.focus-ribbon {
  position: relative;
  box-sizing: border-box;
  background: var(--cg-accent);

  @include mq($until: m) {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include mq(m) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  @include mq(xl) {
    min-height: 60vh;
  }
}

.focus-ribbon__bg-image {
  position: relative;
  width: 100%;
  aspect-ratio: 1;

  img {
    @include image-fit(cover);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include mq(m) {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.focus-ribbon__ribbon {
  position: relative;
  z-index: 1;
  padding: $spacing * 1.5 col(2);
  background: $c-white;
  transform-origin: center left;

  @include mq(m) {
    margin-right: col(7);
    padding: $spacing * 3 col(1);
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
  }

  @include mq(wrapper) {
    /* stylelint-disable scss/operator-no-newline-after */
    margin-right: calc(
      calc(calc(100% - var(--content-width-max)) * 0.5) +
        col-based-on-width(9, var(--content-width-max))
    );
    padding-left: calc(
      calc(calc(100% - var(--content-width-max)) * 0.5) +
        col-based-on-width(2, var(--content-width-max))
    );
    /* stylelint-enable scss/operator-no-newline-after */
  }
}

.focus-ribbon__ribbon__title {
  margin-bottom: 3rem;

  @include mq(xl) {
    line-height: 6.4rem;
  }
}

.focus-ribbon__links {
  @include s-space;

  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  @include mq($until: 's') {
    flex-direction: column;
  }
}

.focus-ribbon__links__item {
  margin-right: $spacing * 0.5;

  &:last-child {
    margin-right: 0;
  }

  @include mq($until: 's') {
    margin-bottom: 1rem;
  }
}
</style>
